#blueScreenPageHTML{
    background-color: #020183;
    color: #fff;
}

#blueScreenPageBody{
    display: flex;
    height: 100vh;
}

#blueScreenPage{
    width: 1200px;
    float: none;
    margin: auto;
    font-size: 30px;

    p{
        margin: 20px 0px;
    }
}

@media only screen and (max-width: $mqMedium){
    #blueScreenPage{
        font-size: 15px;
    }
}