// @import 'style.scss';

#resultPageBody{
    overflow: hidden;
}

#resultPage{
    overflow: hidden;
    
    counter{
        display: block;
        margin: 10px 0px;
    }

    results{
        display: block;
        max-width: 650px;

        result{
            display: block;
            margin: 30px 0px;

            url, title, description{
                display: block;
            }

            url{
                font-size: 14px;
                color: #888;
            }

            title{
                color: #8ab4f8;
                font-size: 20px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }

    clip{
        @extend .flex;
        justify-content: end;

        position: absolute;
        bottom: 10px;
        right: 10px;
        width: 300px;
        max-width: 300px;

        icon{
            width: 100px;
            height: 150px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .speech-bubble{
            margin: 0px;
            padding: 10px;
            max-width: 200px;
        }

        button{
            padding: 2px 10px;
            margin: 10px 2px 0px 0px;
        }
    }

    @media only screen and (max-width: $mqMedium){
        clip{
            width: 100%;
            left: 0px;
            right: 0px;
            bottom: 0px;
            top: 0px;
            background: rgba(255,255,255,0.7);

            .speech-bubble {
                max-width: calc(100% - 110px);
            }
        }
    }
}