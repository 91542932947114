.hide {
    display: none;
}

.glitchImg {
    position: relative;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &#underConstruction{
        width: $coverPageImageWidth;
        height: $coverPageImageHeight;

        .before,
        .after {
            width: $coverPageImageWidth;
            height: $coverPageImageHeight;
        }
    }

    .before,
    .after {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        clip: rect(0, 0, 0, 0);
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }

    .before {
        left: -6px;
        animation: glitch-effect 2s infinite linear alternate-reverse;
        filter: grayscale(100%);
    }
    .after {
        left: 6px;
        animation: glitch-effect 3s infinite linear alternate-reverse;
        filter: contrast(5);
    }
}

$steps: 17;

@keyframes glitch-effect{
    @for $i from 0 through $steps{
        #{percentage($i*(calc(1/$steps)))}{
            // clip: rect(random(300) + px, 400px, random(300) + px, 0);
            clip: rect(random(300) + px, 100%, random(300) + px, 0);
        }
    }
}