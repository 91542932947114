.speech-bubble {
	position: relative;
	background: #fafa64;
	border-radius: .4em;

    padding: 10px;
    margin: 10px 30px 10px 10px;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	width: 0;
	height: 0;
	border: 21px solid transparent;
	border-left-color: #fafa64;
	border-right: 0;
	border-bottom: 0;
	margin-top: -10.5px;
	margin-right: -21px;
}