@mixin w1SideBar{
    width: 200px;
    max-width: 100%;

    img{
        max-width: 100%;
        height: auto !important;
    }
}

@mixin w1Content{
    width: 500px;
    max-width: 100%;
}

@mixin w1BG{
    background: rgba(45,253,231,1);
}

@mixin w1BGGradient{
    background: linear-gradient(90deg, rgba(195,34,182,1) 0%, rgba(45,253,231,1) 100%);
}

@mixin w1Top{
    vertical-align: top;
}

@mixin scrollbars(
    $size: 10px,
    $foreground-color: #999,
    $background-color: #333
) {
    // For Chrome & Safari
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }
    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }
    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // Standard version (Firefox only for now)
    scrollbar-color: $foreground-color $background-color;
}

blink {
    animation: 2s linear infinite condemned_blink_effect;
}

@keyframes condemned_blink_effect {
    0% {
        visibility: hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
}

#oldSchoolPageBody{
    @include scrollbars(20px, rgba(195,34,182,1), rgba(45,253,231,1));
}

#oldSchoolPage{
    #logo{
        @include w1SideBar;
        text-align: right;
    }

    #menu{
        @include w1SideBar;
        @include w1Top;
        @include w1BG;
        @include w1BGGradient;

        ul{
            list-style: none;
            margin: 0px;

            a{
                display: block;
                background: rgba(195,34,182,1);
                color: #fff;
                text-align: center;
                text-decoration: underline;
                font-weight: bold;
                text-transform:capitalize;
                cursor: pointer;

                &:hover{
                    text-decoration: line-through;
                }
            }

            li li a{
                background: none;
                color: #0000ff;
                text-align: right;
            }
        }

        a img{
            border: 2px solid #0000ff;
        }
    }

    #header{
        @include w1Content;
        @include w1BG;
        @include w1BGGradient;
        text-align: center;
    }

    #content{
        @include w1Content;
        @include w1Top;
        @include w1BG;
        p{
            margin: 20px 0px;
        }
        img{
            max-width: 100%;
        }
    }

    .glitter{
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0px;
        color: #000;
        font-weight: bold;

        &:nth-of-type(1n){ color: #00AAFF; }
        &:nth-of-type(2n){ color: #AA00FF; }
        &:nth-of-type(3n){ color: #FF00AA; }
        &:nth-of-type(4n){ color: #FFAA00; }
        &:nth-of-type(5n){ color: #AAFF00; }
    }
}