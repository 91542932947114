$coverPageImageWidth: 400px;
$coverPageImageHeight: 300px;

$mqMedium: 600px;
$mqSmall: 450px;

$defaultFont: 'VT323', monospace;

@import 'underConstruction/glitch.scss'; // needed for under construction
@import 'underConstruction/underConstruction.scss';
@import 'result/result.scss';
@import 'result/bubble.scss';
@import 'oldSchool/oldSchool.scss';
@import 'oldSchool/alert.scss';
@import 'blueScreen/blueScreen.scss';

*{
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

h1{
    margin: 0px;
}

main{
    display: block;
    position: relative;
    max-width: 100%;
    font-size: 18px;
    padding: 20px;
    font-family: monospace;

    &#underConstructionPage,
    &#qrPage{
        padding: 15vh 0 0;
        text-align: center;
        font-family: $defaultFont;
    }

    &#resultPage{
        font-family: $defaultFont;
        max-height: 100vh;
    }
}

input{
    color: #000;
    width: 300px;
    padding: 8px 15px;
    border-radius: 3px;
    border: 1px solid #000;

    #underConstructionPage &{
        float: none;
        margin: 0 auto;
    }

    &.dissolve{
        opacity: 0;
        transition: opacity 5000ms ease;
    }
}

.center{
    text-align: center;
}

.flex{
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

flex{
    @extend .flex;
    justify-content: flex-start;
    gap: 30px;

    #resultPage &:first-of-type{
        border-bottom: 1px dashed #222;
        padding: 0px 0px 20px;
    }

    &.column{
        flex-direction: column;
    }
}

cookie{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 25px;
    height: 25px;
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
    cursor: help;
    opacity: 0.4;

    &.active{
        opacity: 1;
        .tooltip {
            visibility: visible;
        }
    }

    .tooltip {
        visibility: hidden;
        width: 120px;
        background-color: black;
        color: #fff;
        text-align: center;
        font-family: $defaultFont;
        padding: 5px 0;
        border-radius: 6px;

        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1001;
        top: 100%;
        right: 100%;
        // margin-left: -60px;
    }
}