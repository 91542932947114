#underConstructionPageBody{
    overflow: hidden;
}

.coverPageImage{
    @extend .glitchImg;

    display: block;
    float: none;
    margin: 0 auto 20px;
    background-size:contain;
    width: 200px;
    height: 200px;

    &.dissolve{
        opacity: 0;
        transition: opacity 5000ms ease;
    }

    #resultPage &{
        width: 75px;
        height: 75px;
        margin: 0px;
        display: inline-block;
    }

    .before,
    .after{
        background-size:contain;
        width: 200px;
        height: 200px;
    }
}