$alertWidth: 40vw;
$alertHeight: 25vh;

alert{
    display: block;
    position: absolute;

    left: calc((100vw - $alertWidth) / 2);
    top: calc((100vh - $alertHeight) / 2);
    width: $alertWidth;
    height: $alertHeight;

    background: #fff;
    border: 1px solid #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

    .bar{
        background-color: #0000a8;
        border-bottom: 1px solid #0000a8;
        color: #c0c4c8;
        font-size: 18px;
        line-height: 18px;
        height: 19px;
    }

    .content{
        padding: 20px 30px;
        text-align: center;

        .warning{
            display: grid;
            text-align: left;
            grid-template-columns: 50px 1fr;
        }

        button{
            background-color: #c0c4c8;
            // border: 1px solid #000;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            margin: 20px 10px;
            padding: 2px 5px;
        }
    }

    .stop{
        color: #a80053;
        font-size: 32px;
    }
}

@media only screen and (max-width: $mqMedium){
    alert{
        width: 90vw;
        left: 5vw;
    }
}

@media only screen and (max-width: $mqSmall){
    alert{
        height: auto;
    }
}