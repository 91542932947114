.hide {
  display: none;
}

.glitchImg, .coverPageImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: relative;
}

.glitchImg#underConstruction, #underConstruction.coverPageImage, .glitchImg#underConstruction .before, #underConstruction.coverPageImage .before, .glitchImg#underConstruction .after, #underConstruction.coverPageImage .after {
  width: 400px;
  height: 300px;
}

.glitchImg .before, .coverPageImage .before, .glitchImg .after, .coverPageImage .after {
  clip: rect(0, 0, 0, 0);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.glitchImg .before, .coverPageImage .before {
  filter: grayscale();
  animation: 2s linear infinite alternate-reverse glitch-effect;
  left: -6px;
}

.glitchImg .after, .coverPageImage .after {
  filter: contrast(5);
  animation: 3s linear infinite alternate-reverse glitch-effect;
  left: 6px;
}

@keyframes glitch-effect {
  0% {
    clip: rect(243px, 100%, 254px, 0);
  }

  5.88235% {
    clip: rect(206px, 100%, 164px, 0);
  }

  11.7647% {
    clip: rect(194px, 100%, 179px, 0);
  }

  17.6471% {
    clip: rect(136px, 100%, 137px, 0);
  }

  23.5294% {
    clip: rect(290px, 100%, 41px, 0);
  }

  29.4118% {
    clip: rect(166px, 100%, 22px, 0);
  }

  35.2941% {
    clip: rect(24px, 100%, 21px, 0);
  }

  41.1765% {
    clip: rect(22px, 100%, 203px, 0);
  }

  47.0588% {
    clip: rect(1px, 100%, 219px, 0);
  }

  52.9412% {
    clip: rect(101px, 100%, 247px, 0);
  }

  58.8235% {
    clip: rect(82px, 100%, 177px, 0);
  }

  64.7059% {
    clip: rect(92px, 100%, 57px, 0);
  }

  70.5882% {
    clip: rect(167px, 100%, 188px, 0);
  }

  76.4706% {
    clip: rect(223px, 100%, 157px, 0);
  }

  82.3529% {
    clip: rect(167px, 100%, 4px, 0);
  }

  88.2353% {
    clip: rect(53px, 100%, 88px, 0);
  }

  94.1176% {
    clip: rect(268px, 100%, 158px, 0);
  }

  100% {
    clip: rect(106px, 100%, 276px, 0);
  }
}

#underConstructionPageBody {
  overflow: hidden;
}

.coverPageImage {
  float: none;
  background-size: contain;
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
  display: block;
}

.coverPageImage.dissolve {
  opacity: 0;
  transition: opacity 5s;
}

#resultPage .coverPageImage {
  width: 75px;
  height: 75px;
  margin: 0;
  display: inline-block;
}

.coverPageImage .before, .coverPageImage .after {
  background-size: contain;
  width: 200px;
  height: 200px;
}

#resultPageBody, #resultPage {
  overflow: hidden;
}

#resultPage counter {
  margin: 10px 0;
  display: block;
}

#resultPage results {
  max-width: 650px;
  display: block;
}

#resultPage results result {
  margin: 30px 0;
  display: block;
}

#resultPage results result url, #resultPage results result title, #resultPage results result description {
  display: block;
}

#resultPage results result url {
  color: #888;
  font-size: 14px;
}

#resultPage results result title {
  color: #8ab4f8;
  font-size: 20px;
}

#resultPage results result title:hover {
  cursor: pointer;
}

#resultPage clip {
  justify-content: end;
  width: 300px;
  max-width: 300px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#resultPage clip icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 150px;
}

#resultPage clip .speech-bubble {
  max-width: 200px;
  margin: 0;
  padding: 10px;
}

#resultPage clip button {
  margin: 10px 2px 0 0;
  padding: 2px 10px;
}

@media only screen and (width <= 600px) {
  #resultPage clip {
    background: #ffffffb3;
    width: 100%;
    inset: 0;
  }

  #resultPage clip .speech-bubble {
    max-width: calc(100% - 110px);
  }
}

.speech-bubble {
  background: #fafa64;
  border-radius: .4em;
  margin: 10px 30px 10px 10px;
  padding: 10px;
  position: relative;
}

.speech-bubble:after {
  content: "";
  border: 0;
  border-top: 21px solid #0000;
  border-left: 21px solid #fafa64;
  width: 0;
  height: 0;
  margin-top: -10.5px;
  margin-right: -21px;
  position: absolute;
  top: 50%;
  right: 0;
}

blink {
  animation: 2s linear infinite condemned_blink_effect;
}

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden;
  }

  50% {
    visibility: hidden;
  }

  100% {
    visibility: visible;
  }
}

#oldSchoolPageBody {
  scrollbar-color: #c322b6 #2dfde7;
}

#oldSchoolPageBody::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

#oldSchoolPageBody::-webkit-scrollbar-thumb {
  background: #c322b6;
}

#oldSchoolPageBody::-webkit-scrollbar-track {
  background: #2dfde7;
}

#oldSchoolPage #logo {
  text-align: right;
  width: 200px;
  max-width: 100%;
}

#oldSchoolPage #logo img {
  max-width: 100%;
  height: auto !important;
}

#oldSchoolPage #menu {
  vertical-align: top;
  background: linear-gradient(90deg, #c322b6 0%, #2dfde7 100%);
  width: 200px;
  max-width: 100%;
}

#oldSchoolPage #menu img {
  max-width: 100%;
  height: auto !important;
}

#oldSchoolPage #menu ul {
  margin: 0;
  list-style: none;
}

#oldSchoolPage #menu ul a {
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  background: #c322b6;
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

#oldSchoolPage #menu ul a:hover {
  text-decoration: line-through;
}

#oldSchoolPage #menu ul li li a {
  color: #00f;
  text-align: right;
  background: none;
}

#oldSchoolPage #menu a img {
  border: 2px solid #00f;
}

#oldSchoolPage #header {
  text-align: center;
  background: linear-gradient(90deg, #c322b6 0%, #2dfde7 100%);
  width: 500px;
  max-width: 100%;
}

#oldSchoolPage #content {
  vertical-align: top;
  background: #2dfde7;
  width: 500px;
  max-width: 100%;
}

#oldSchoolPage #content p {
  margin: 20px 0;
}

#oldSchoolPage #content img {
  max-width: 100%;
}

#oldSchoolPage .glitter {
  color: #000;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

#oldSchoolPage .glitter:nth-of-type(n) {
  color: #0af;
}

#oldSchoolPage .glitter:nth-of-type(2n) {
  color: #a0f;
}

#oldSchoolPage .glitter:nth-of-type(3n) {
  color: #f0a;
}

#oldSchoolPage .glitter:nth-of-type(4n) {
  color: #fa0;
}

#oldSchoolPage .glitter:nth-of-type(5n) {
  color: #af0;
}

alert {
  background: #fff;
  border: 1px solid #fff;
  width: 40vw;
  height: 25vh;
  display: block;
  position: absolute;
  top: 37.5vh;
  left: 30vw;
  box-shadow: 0 6px 24px #0000000d, 0 0 0 1px #00000014;
}

alert .bar {
  color: #c0c4c8;
  background-color: #0000a8;
  border-bottom: 1px solid #0000a8;
  height: 19px;
  font-size: 18px;
  line-height: 18px;
}

alert .content {
  text-align: center;
  padding: 20px 30px;
}

alert .content .warning {
  text-align: left;
  grid-template-columns: 50px 1fr;
  display: grid;
}

alert .content button {
  background-color: #c0c4c8;
  margin: 20px 10px;
  padding: 2px 5px;
  box-shadow: 0 3px 8px #0000003d;
}

alert .stop {
  color: #a80053;
  font-size: 32px;
}

@media only screen and (width <= 600px) {
  alert {
    width: 90vw;
    left: 5vw;
  }
}

@media only screen and (width <= 450px) {
  alert {
    height: auto;
  }
}

#blueScreenPageHTML {
  color: #fff;
  background-color: #020183;
}

#blueScreenPageBody {
  height: 100vh;
  display: flex;
}

#blueScreenPage {
  float: none;
  width: 1200px;
  margin: auto;
  font-size: 30px;
}

#blueScreenPage p {
  margin: 20px 0;
}

@media only screen and (width <= 600px) {
  #blueScreenPage {
    font-size: 15px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

main {
  max-width: 100%;
  padding: 20px;
  font-family: monospace;
  font-size: 18px;
  display: block;
  position: relative;
}

main#underConstructionPage, main#qrPage {
  text-align: center;
  padding: 15vh 0 0;
  font-family: VT323, monospace;
}

main#resultPage {
  max-height: 100vh;
  font-family: VT323, monospace;
}

input {
  color: #000;
  border: 1px solid #000;
  border-radius: 3px;
  width: 300px;
  padding: 8px 15px;
}

#underConstructionPage input {
  float: none;
  margin: 0 auto;
}

input.dissolve {
  opacity: 0;
  transition: opacity 5s;
}

.center {
  text-align: center;
}

.flex, flex, #resultPage clip {
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  display: flex;
}

flex {
  justify-content: flex-start;
  gap: 30px;
}

#resultPage flex:first-of-type {
  border-bottom: 1px dashed #222;
  padding: 0 0 20px;
}

flex.column {
  flex-direction: column;
}

cookie {
  z-index: 1000;
  cursor: help;
  opacity: .4;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

cookie.active {
  opacity: 1;
}

cookie.active .tooltip {
  visibility: visible;
}

cookie .tooltip {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1001;
  background-color: #000;
  border-radius: 6px;
  width: 120px;
  padding: 5px 0;
  font-family: VT323, monospace;
  position: absolute;
  top: 100%;
  right: 100%;
}
/*# sourceMappingURL=index.10f91548.css.map */
